import { ContactLinkItemType } from '../ContactInfo/types'
import { FC, useMemo } from 'react'
import CallFilled from '@mui/icons-material/Call'
import CallOutlined from '@mui/icons-material/CallOutlined'
import EmailFilled from '@mui/icons-material/Mail'
import EmailOutlined from '@mui/icons-material/MailOutlined'
import LocationFilled from '@mui/icons-material/LocationOn'
import LocationOutlined from '@mui/icons-material/LocationOnOutlined'
import TextFilled from '@mui/icons-material/Message'
import TextFilledOutlined from '@mui/icons-material/MessageOutlined'
import WebsiteFilled from '@mui/icons-material/Language'
import WebsiteOutlined from '@mui/icons-material/LanguageOutlined'

const iconMap = {
  email: {
    filled: EmailFilled,
    outlined: EmailOutlined
  },
  location: {
    filled: LocationFilled,
    outlined: LocationOutlined
  },
  text: {
    filled: TextFilled,
    outlined: TextFilledOutlined
  },
  call: {
    filled: CallFilled,
    outlined: CallOutlined
  },
  website: {
    filled: WebsiteFilled,
    outlined: WebsiteOutlined
  }
}

export const ContactIconComponent: FC<{
  isOutline: boolean
  type?: ContactLinkItemType
  color?: string
  size?: number
}> = ({ type, color = '#000', size, isOutline }) => {
  const Component = useMemo(
    () => (type ? iconMap[type]?.[isOutline ? 'outlined' : 'filled'] : null),
    [type, isOutline]
  )

  return Component ? <Component sx={{ color, fontSize: size }} /> : <></>
}
